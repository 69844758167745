<template>
    <div class="mt-4">
        <div class="d-flex flex-row-reverse mb-3 pa-2   grey darken-3 ">
            <v-btn
                    :disabled="(!valid || buttonLoading)"
                    :loading="buttonLoading"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="validate"
            >Save
            </v-btn>
            <v-btn
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="clear"
            >clear
            </v-btn>
        </div>
        <v-alert
                v-if="Object.keys(errors).length>0"
                dense
                text
                type="error"
        >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
                <li
                        v-for="(error, index) in errors"
                        :key="index"
                >{{ error.message || error }}
                </li>
            </ul>
        </v-alert>
        <v-form
                ref="form"
                v-model="valid"
                :disabled="buttonLoading"
                lazy-validation
        >

            <v-row>
                <v-col cols="12">
                    <div :key="keyForm" class="d-flex flex-column">
                        <div v-for="(parent,index) in prices.filter(c => c.parentID === 0)"
                             :key="prices[index].id">
                            <div v-if="prices.filter(c => c.parentID === parent.id && c.isActive===true).length" class="black--text mt-6 body-1">{{
                                    parent.name
                                }}
                            </div>
                            <div v-for="(child,index) in prices.filter(c => c.parentID === parent.id && c.isActive===true)"
                                 :key="prices[index].id"
                                 class="d-inline-flex mr-6">

                                <v-text-field v-model="itemsPrice[child.id]" :label="child.name" :name="child.name"
                                              :rules="(child.isMain!==true && child.isDiscount!==true)?[]:[v => !!v || 'Please fill this field']"
                                              min="0" type="text"

                                ></v-text-field>
                            </div>
                        </div>
                    </div>
                </v-col>

            </v-row>
        </v-form>
    </div>

</template>

<script>
import networks from '../../services/networks';
import {urlPrices, urlProductPrices, urlUpdateProductPrice,} from "@/config";

export default {
    props: {
        id: Number,
    },
    data: () => ({
        valid: true,
        prices: [],
        itemsPrice: [],
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        loading: true,
        keyForm: 0,
    }),

    created() {
        this.getPricesFromApi();
        this.getProductPricesFromApi();
    },

    methods: {
        getProductPricesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlProductPrices + this.id)
                .then(async response => {
                    response.data.map(e => {
                        this.itemsPrice[e.priceID] = e.price;
                    });
                    this.keyForm++;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getPricesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlPrices)
                .then(response => {
                    this.prices = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        validate() {
            if (this.$refs.form.validate() && this.id) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {

            let data = this.itemsPrice.map((price, index) => {
                return {"priceID": index, "price": price};
            });
            data = data.filter((item) => item.price !== '');

            networks.fetchFromWeb(urlUpdateProductPrice + this.id, 2, data)
                .then(response => {

                    if (response.status == 200) {
                        this.clear(false);
                        this.dialogAddItem = false;
                        alert("the data has been edited successfully");
                    }


                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });


        },

        clear(withItems) {
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            if (withItems) this.itemsPrice = [];
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


    },
}
</script>



