<template>
    <div class="mt-4 ">
        <div class="d-flex flex-row-reverse mb-3 pa-2   grey darken-3 ">
            <v-btn
                    :disabled="(!valid || buttonLoading)"
                    :loading="buttonLoading"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="validate"
            >Save
            </v-btn>
            <v-btn
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="clear"
            >clear
            </v-btn>
        </div>
        <v-alert
                v-if="Object.keys(errors).length>0"
                dense
                text
                type="error"
        >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
                <li
                        v-for="(error, index) in errors"
                        :key="index"
                >{{ error.message || error }}
                </li>
            </ul>
        </v-alert>
        <v-form
                ref="form"
                v-model="valid"
                :disabled="buttonLoading"
                lazy-validation
        >
            <v-row>
                <v-col cols="12">
                    <div class="d-flex flex-column ">
                        <div v-for="category in categories"
                             :key="category.id" class="my-4">


                            <div class="text-body-1">{{ category.name }}</div>

                            <v-list lines="one">


                                <v-list-item
                                        v-for="addon in category.Addons"
                                        :key="addon.id"
                                        class="d-inline-flex mx-4 my-2  lighten-3 rounded elevation-2"
                                >
                                    <v-list-item-avatar size="60">
                                        <v-img v-if="addon.image!==null" :src="pathImageUrl+addon.image"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="body-1 ">{{ addon.name }}</v-list-item-title>
                                        <v-list-item-subtitle class=" pl-2">
                                            <v-switch
                                                    v-model="itemsAddonDefault[addon.id]"
                                                    :disabled="!itemsAddon.some(e=>e===addon.id)"
                                                    color="blue-grey darken-2"
                                                    dense
                                                    inset
                                                    label="default Addon for this boiler"
                                                    @change="setDefault($event,category,addon.id)"

                                            ></v-switch>

                                        </v-list-item-subtitle>

                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-checkbox v-model="itemsAddon" :value="addon.id" light @change="disableAddon($event,category,addon.id)"/>

                                    </v-list-item-icon>


                                </v-list-item>
                            </v-list>

                        </div>
                    </div>
                </v-col>

            </v-row>
        </v-form>
    </div>

</template>

<script>
import networks from '../../services/networks';
import {pathImageUrl, urlAddons, urlCategories, urlProductAddons, urlUpdateProductAddon,} from "@/config";

export default {
    props: {
        id: Number,
    },
    data: () => ({
        valid: true,
        categories: [],
        itemsAddon: [],
        itemsAddonDefault: [],
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        loading: true,
        pathImageUrl: pathImageUrl,
    }),

    created() {
        this.getAddonProductsFromApi();
        this.getCategoriesFromApi();

    },

    methods: {
        getAddonProductsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlProductAddons + this.id)
                .then(response => {
                    response.data.map(e => {
                        this.itemsAddon.push(e.addonID);
                        this.itemsAddonDefault[e.addonID] = e.isDefault;
                    });
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getCategoriesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlCategories + urlAddons)
                .then(response => {
                    this.categories = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        setDefault(val, category, addonID) {
            if (val) {
              this.itemsAddonDefault[addonID] = true;

            }
        },

        disableAddon(val, category, addonID) {
            if (!this.itemsAddon.some(e => e === addonID)) {
                if (this.itemsAddonDefault[addonID] === true) this.itemsAddonDefault[addonID] = false;
            }
        },

        validate() {
            if (this.$refs.form.validate() && this.itemsAddon.filter(c => c != null).length && this.id) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {
            let addonDefaultIDs = [];
            let addonIDs = this.itemsAddon.filter(c => c != null);
            this.itemsAddonDefault.forEach((e, index) => {
                if (e) addonDefaultIDs.push(index);
            });
            let data = {addonIDs, addonDefaultIDs};
            networks.fetchFromWeb(urlUpdateProductAddon + this.id, 2, data)
                .then(response => {

                    if (response.status == 200) {
                        this.clear(false);
                        this.dialogAddItem = false;
                        alert("the data has been edited successfully");
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });

        },

        clear(withItems) {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            if (withItems) {
                this.itemsAddon = [];
                this.itemsAddonDefault = [];
            }
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },

    },
}
</script>



