<template>
    <div class="mt-4">
        <div class="d-flex flex-row-reverse mb-3 pa-2   grey darken-3 ">
            <v-btn
                    :disabled="(!valid || buttonLoading)"
                    :loading="buttonLoading"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="validate(true)"
            >Save To All
            </v-btn>
            <v-btn
                :disabled="(!valid || buttonLoading)"
                :loading="buttonLoading"
                class="mr-2 px-3"
                color="grey"
                dark
                @click="validate"
            >Save
            </v-btn>
          <v-btn
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="clear"
            >clear
            </v-btn>
        </div>
        <v-alert
                v-if="Object.keys(errors).length>0"
                dense
                text
                type="error"
        >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
                <li
                        v-for="(error, index) in errors"
                        :key="index"
                >{{ error.message || error }}
                </li>
            </ul>
        </v-alert>
        <v-form
                ref="form"
                v-model="valid"
                :disabled="buttonLoading"
                lazy-validation

        >
            <v-row>
                <v-col cols="12">
                    <div class="d-flex flex-column">
                        <div v-for="(city) in cities"
                             :key="city.id">
                            <div class="black--text mt-6 body-1">{{ city.name }}
                                <v-text-field :label="'Default price for '+city.name" :name="city.name" min="0"
                                              type="number" @change="changePriceToAll($event,city)"
                                ></v-text-field>
                            </div>
                            <div :key="keyForm">
                                <div v-for="(child,indexChild) in city.Postcodes.filter(c => c.isActive===true)"
                                     :key="indexChild"
                                     class="d-inline-flex mr-6">

                                    <v-text-field v-model="itemsPrice[child.id]" :label="child.name" :name="child.name"
                                                  min="0" type="number"

                                    ></v-text-field>
                                </div>
                            </div>

                        </div>
                    </div>
                </v-col>

            </v-row>
        </v-form>
    </div>
</template>

<script>
import networks from '../../services/networks';
import {urlCitiesPostcodes, urlProductPricePostcodes, urlUpdateProductPricePostcode,} from "@/config";

export default {
    props: {
        id: Number,
    }, data: () => ({
        valid: true,
        products: [],
        errors: [],
        cities: [],
        itemsPrice: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        loading: true,
        keyForm: 0,
    }),

    created() {
        this.getPostCodePricesFromApi();
        this.getCitiesPostcodesFromApi();
    },

    methods: {
        getPostCodePricesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlProductPricePostcodes + this.id)
                .then(response => {
                    response.data.map(e => {
                        this.itemsPrice[e.postCodeID] = e.price;
                    });
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getCitiesPostcodesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlCitiesPostcodes)
                .then(response => {
                    this.cities = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        changePriceToAll(val, city) {
            city.Postcodes.map(e => {
                if (e.isActive === true)
                    this.itemsPrice[e.id] = val;
            });
            this.keyForm++;
        },

      validate(isAllProducts) {
            if (this.itemsPrice.length && this.id) {
                this.buttonLoading = true;
                this.errors = [];
              this.saveItem(isAllProducts);
            }
        },

      saveItem(isAllProducts) {
            let data = this.itemsPrice.map((price, index) => {
                return {"postCodeID": index, "price": price};
            });
            data = data.filter((item) => item.price !== '');

        networks.fetchFromWeb(urlUpdateProductPricePostcode + this.id + "/" + isAllProducts, 2, data)
                .then(response => {
                    if (response.status == 200) {
                        this.clear(false);
                        this.dialogAddItem = false;
                        alert("the data has been edited successfully");
                        this.getPostCodePricesFromApi();
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });


        },

        clear(withItems) {
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            if (withItems) this.itemsPrice = [];
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },
    },
}
</script>



