<template>
    <div class="mt-4">
        <div class="d-flex flex-row-reverse mb-3 pa-2   grey darken-3 ">
            <v-btn
                    :disabled="(!valid || buttonLoading)"
                    :loading="buttonLoading"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="validate"
            >Save
            </v-btn>
            <v-btn
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="clear"
            >clear
            </v-btn>
        </div>
        <v-alert
                v-if="Object.keys(errors).length>0"
                dense
                text
                type="error"
        >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
                <li
                        v-for="(error, index) in errors"
                        :key="index"
                >{{ error.message || error }}
                </li>
            </ul>
        </v-alert>
        <v-form
                ref="form"
                v-model="valid"
                :disabled="buttonLoading"
                lazy-validation

        >
            <v-row>
                <v-col cols="12">
                    <div class="d-flex flex-column">
                        <div v-for="(parent,index) in sizes.filter(c => c.parentID === 0)"
                             :key="sizes[index].id">
                            <div v-if="sizes.filter(c => c.parentID === parent.id && c.isActive===true).length" class="black--text mt-6 body-1">{{
                                    parent.name
                                }}
                            </div>
                            <div v-for="(child,index) in sizes.filter(c => c.parentID === parent.id && c.isActive===true)"
                                 :key="sizes[index].id"
                                 class="d-inline-flex mx-4">
                                <v-checkbox v-model="itemsSize" :label="child.name" :value="child.id" light/>

                            </div>
                        </div>
                    </div>
                </v-col>

            </v-row>
        </v-form>
    </div>
</template>

<script>
import networks from '../../services/networks';
import {urlProductSize, urlSizes, urlUpdateProductSize} from "@/config";

export default {
    props: {
        id: Number,
    },
    data: () => ({
        sizes: [],
        itemsSize: [],
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        loading: true,
        valid: true,
    }),

    created() {
        this.getSizesFromApi();
        this.getProductSizesFromApi();
    },

    methods: {
        getProductSizesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlProductSize + this.id)
                .then(response => {
                    this.itemsSize = response.data.map(e => e.sizeID);
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getSizesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlSizes)
                .then(response => {
                    this.sizes = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        validate() {

            if (this.$refs.form.validate() && this.itemsSize.filter(c => c != null).length && this.id) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {
            let sizeIDs = this.itemsSize.filter(c => c != null);
            let data = {sizeIDs: sizeIDs};


            networks.fetchFromWeb(urlUpdateProductSize + this.id, 2, data)
                .then(response => {

                    if (response.status == 200) {
                        this.clear(false);
                        this.dialogAddItem = false;
                        alert("the data has been edited successfully");
                    }


                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });


        },

        clear(withItems) {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            if (withItems) this.itemsSize = [];
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


    },
}
</script>



