<template>
    <div class="mt-4">
        <div class="d-flex flex-row-reverse mb-3 pa-2   grey darken-3 ">
            <v-btn
                    :disabled="(!valid || buttonLoading)"
                    :loading="buttonLoading"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="validate"
            >Save
            </v-btn>
            <v-btn
                    :disabled="(buttonLoading)"
                    class="mr-2 px-3"
                    color="grey"
                    dark
                    @click="clear"
            >clear
            </v-btn>
        </div>
        <v-alert
                v-if="Object.keys(errors).length>0"
                dense
                text
                type="error"
        >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
                <li
                        v-for="(error, index) in errors"
                        :key="index"
                >{{ error.message || error }}
                </li>
            </ul>
        </v-alert>

        <v-dialog
                v-model="dialogDelete"
                max-width="425"
                persistent
        >
            <v-card>
                <v-card-title>
                    Delete Image
                </v-card-title>

                <v-card-text class=" ">
                    Are you sure for continue deleting this image ?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="primary"
                            text
                            @click="dialogDelete = false"
                    >
                        Reject
                    </v-btn>

                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="red darken-2"
                            text
                            @click="destroyImage"
                    >
                        Process
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-if="!dialogDelete"
                v-model="buttonLoading"
                hide-overlay
                persistent
                width="300"
        >
            <v-card
                    color="indigo"
                    dark
            >
                <v-card-text>
                    <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

                    <v-progress-linear
                            class="my-2"
                            color="white"
                            indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-form
                ref="form"
                v-model="valid"
                :disabled="buttonLoading"
                lazy-validation

        >
            <v-row>

                <v-col cols="12" md="12" sm="12">

                    <v-file-input
                            v-model="previewImages"
                            :rules="imagesRules"
                            accept="image/png, image/jpeg"
                            class="pa-2"
                            clearable
                            color="primary"
                            counter
                            label="Product Image"
                            multiple
                            outlined
                            placeholder="Select images to uploads"
                            prepend-icon="mdi-camera"
                            required
                            show-size
                            @click:clear="clear"
                    >
                        <template v-slot:selection="{ index, text }">
                            <v-chip
                                    v-if="index < 2"
                                    color="primary"
                                    dark
                                    label
                                    small
                            >
                                {{ text }}
                            </v-chip>

                            <span
                                    v-else-if="index === 2"
                                    class="overline blue--text text--darken-3 mx-2"
                            >+{{ images.length - 2 }} Images</span>
                        </template>

                    </v-file-input>
                    <v-file-input id="fileUpload" v-model="previewReplaceImage" accept="image/png, image/jpeg" style="display: none" type="file"/>

                </v-col>

                <v-col v-for="(img,index) in images" :key="index" class="d-flex flex-column align-center " lg="3" md="4" sm="6" xl="2">
                    <v-img
                            :src="checkImageScrType(img)"
                            aspect-ratio="1.7"
                            class="grey darken-4"
                            height="500"
                            style=" border-radius: 15px; margin:auto;background-size: auto;"
                            width="300"
                    />

                    <div class="d-inline ma-2  ">
                        <v-btn
                                :dark="!buttonLoading"
                                :disabled="buttonLoading"
                                class="mx-1 px-2"
                                color="red"
                                @click="clickDeleteImage(img)"
                        >
                            Delete
                            <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                            </template>
                        </v-btn>

                        <v-btn
                                v-if="isImage(img)"
                                :dark="!buttonLoading"
                                :disabled="buttonLoading"
                                class="mx-1 px-2"
                                color="blue"
                                @click="clickReplaceImage(img)"
                        >
                            Replace
                            <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                            </template>
                        </v-btn>


                    </div>

                </v-col>

            </v-row>
        </v-form>
    </div>
</template>

<script>
import networks from '../../services/networks';
import {pathImageUrl, urlCreateProductImage, urlProductImages} from "@/config";

export default {
    props: {
        id: Number,
    },
    data: () => ({
        sizes: [],
        itemsSize: [],
        errors: [],
        buttonLoading: false,
        loading: true,
        valid: true,
        previewImages: [],
        images: [],
        imagesRules: [
            v => (!v || v.length >= 1) || 'Image is required',
            v => (v && v.length <= 40) || 'Total images must be less than or equal 40 images.',
        ],
        dialogDelete: false,
        disableChangeDelete: false,
        deleteImage: null,
        replaceImage: null,
        previewReplaceImage: null,
    }),

    created() {
        this.getProductImagesFromApi();
    },

    watch: {
        previewImages() {
            this.images = this.images.concat(this.previewImages);
        },
        previewReplaceImage() {
            if (this.previewReplaceImage != null) {
                let index = this.images.indexOf(this.replaceImage);
                if (index > -1) {
                    this.images [index] = this.previewReplaceImage;
                    this.saveReplaceImage();
                }
            }
        }
    },

    methods: {
        getProductImagesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlProductImages + this.id)
                .then(response => {
                    this.images = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        checkImageScrType(val) {
            if (!this.isImage(val))
                return URL.createObjectURL(val);
            return pathImageUrl + val.image;


        },
        isImage(val) {
            return ('productID' in val);
        },

        clickReplaceImage(img) {
            this.previewReplaceImage = null;
            this.replaceImage = img;
            document.getElementById("fileUpload").click();
        },
        showDialogDelete(image) {
            this.deleteImage = image;
            this.dialogDelete = true;


        },

        clickDeleteImage(img) {
            if (this.isImage(img)) {
                this.showDialogDelete(img.image);
            } else {
                this.images = this.images.filter((e) => e !== img);
                this.previewImages = this.previewImages.filter((e) => e !== img);
            }
        },

        destroyImage() {

            this.buttonLoading = true;

            networks.fetchFromWeb(urlProductImages + this.id + "/" + this.deleteImage, 3)
                .then(response => {

                    if (response.status == 200) {
                        this.dialogDelete = false;
                        alert("Image has been deleted");
                        this.images = this.images.filter((el) => el.image !== this.deleteImage);
                        this.deleteImage = null;
                    }

                })
                .catch(() => {
                    alert("Failed for process delete image");
                    this.dialogDelete = false;
                }).finally(() => this.buttonLoading = false);

        },


        validate() {
            let arrImages = [];
            arrImages = this.images.filter(e => !this.isImage(e));
            if (this.$refs.form.validate() && this.id && arrImages.length) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem(arrImages);
            }
        },

        saveItem(arrImages) {
            const fd = new FormData();

            arrImages.forEach(img => {
                if (!this.isImage(img)) fd.append("gallery", img);
            });
            fd.append("productID", this.id);


            networks.fetchFromWeb(urlCreateProductImage, 1, fd,true)
                .then(response => {

                    if (response.status == 200) {
                        this.clear(false);
                        alert("the data has been edited successfully");
                        this.getProductImagesFromApi();
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });


        },

        saveReplaceImage() {
            const fd = new FormData();
            fd.append("gallery", this.previewReplaceImage);
            fd.append("imageName", this.replaceImage.image);
            fd.append("productID", this.id);


            networks.fetchFromWeb(urlCreateProductImage, 1, fd,true)
                .then(response => {

                    if (response.status == 200) {
                        this.clear(false);
                        this.getProductImagesFromApi();
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoading = false;
                });


        },

        clear(withItems = true) {
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            this.previewImages = [];
            if (withItems) {
                this.images = this.images.filter(e => this.isImage(e));
            }
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
            this.deleteImage = null;
            this.replaceImage = null;
            this.previewReplaceImage = null;
        },


    },
}
</script>

<style>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>



